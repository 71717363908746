import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import BottomCta from '../../components/bottom-cta'

// Load Page Content
import contentEn from '../../../content/pages/products/sakti.en.yaml'
import contentId from '../../../content/pages/products/sakti.id.yaml'
import OtherProducts from '../../components/other-products'
import StudyCaseSlider, { AllStudyCaseType } from '../../components/study-case'
import SEO from '../../components/seo'

export const query = graphql`
  query SaktiQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    omnichannelStudyCase: allStudyCasesOmnichannel {
      edges {
        node {
          id
          languages {
            id
            description
            locale
            logo
            title
          }
        }
      }
    }
    logo: file(relativePath: { eq: "images/sakti/sakti-logo.svg" }) {
      id
      publicURL
    }
    omniChannelFigure: file(relativePath: { eq: "images/sakti/sakti-figure-omni-channel.svg" }) {
      id
      publicURL
    }
    oneConversationIcon: file(relativePath: { eq: "images/sakti/sakti-icon-one-any-channel.svg" }) {
      id
      publicURL
    }
    intelligentIcon: file(relativePath: { eq: "images/sakti/sakti-icon-itelligent-routing.svg" }) {
      id
      publicURL
    }
    easyIntegrationIcon: file(relativePath: { eq: "images/sakti/sakti-icon-easy-integration.svg" }) {
      id
      publicURL
    }
    integratingVariousChannelIcon: file(
      relativePath: { eq: "images/sakti/sakti-icon-integrating-various-channel.svg" }
    ) {
      id
      publicURL
    }
    comprehensiveDataIcon: file(relativePath: { eq: "images/sakti/sakti-icon-comprehensive-data.svg" }) {
      id
      publicURL
    }
    developStrategiesIcon: file(relativePath: { eq: "images/sakti/sakti-icon-develop-strategies.svg" }) {
      id
      publicURL
    }
    increaseProductivityIcon: file(relativePath: { eq: "images/sakti/sakti-icon-increase-productivity.svg" }) {
      id
      publicURL
    }
    driveTrafficIcon: file(relativePath: { eq: "images/sakti/sakti-icon-drive-traffic.svg" }) {
      id
      publicURL
    }
    betterCustomerExperienceIcon: file(relativePath: { eq: "images/sakti/sakti-icon-better-customer-experience.svg" }) {
      id
      publicURL
    }
    benefitFigure: file(relativePath: { eq: "images/sakti/sakti-figure-benefit.svg" }) {
      id
      publicURL
    }
    webLiveChatFigure: file(relativePath: { eq: "images/sakti/sakti-figure-web-live-chat.svg" }) {
      id
      publicURL
    }
    emailFigure: file(relativePath: { eq: "images/sakti/sakti-figure-email.svg" }) {
      id
      publicURL
    }
    chatbotFigure: file(relativePath: { eq: "images/sakti/sakti-figure-chatbot.svg" }) {
      id
      publicURL
    }
    whatsappBusinessFigure: file(relativePath: { eq: "images/sakti/sakti-figure-whatsapp-business.svg" }) {
      id
      publicURL
    }
  }
`

interface SaktiPagePropsType {
  data: {
    logo: PublicUrlType
    omnichannelStudyCase: AllStudyCaseType
    omniChannelFigure: PublicUrlType
    oneConversationIcon: PublicUrlType
    intelligentIcon: PublicUrlType
    easyIntegrationIcon: PublicUrlType
    integratingVariousChannelIcon: PublicUrlType
    comprehensiveDataIcon: PublicUrlType
    developStrategiesIcon: PublicUrlType
    increaseProductivityIcon: PublicUrlType
    driveTrafficIcon: PublicUrlType
    betterCustomerExperienceIcon: PublicUrlType
    benefitFigure: PublicUrlType
    webLiveChatFigure: PublicUrlType
    emailFigure: PublicUrlType
    chatbotFigure: PublicUrlType
    whatsappBusinessFigure: PublicUrlType
  }
}

const SaktiPage = (props: SaktiPagePropsType): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const {
    logo,
    omnichannelStudyCase,
    omniChannelFigure,
    oneConversationIcon,
    intelligentIcon,
    easyIntegrationIcon,
    integratingVariousChannelIcon,
    comprehensiveDataIcon,
    developStrategiesIcon,
    increaseProductivityIcon,
    driveTrafficIcon,
    betterCustomerExperienceIcon,
    benefitFigure,
    webLiveChatFigure,
    emailFigure,
    chatbotFigure,
    whatsappBusinessFigure,
  } = props.data

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-sakti-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} />
                    <h1 className="uk-heading-small">{content.title}</h1>
                    <p className="uk-visible@m uk-text-lead">{content.description}</p>
                    <a className="uk-button uk-button-primary uk-margin-top" href="https://toschat.com/id/register">
                    {t('Get Free Package')}
                  </a>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  <img width="" height="" alt="" uk-img={logo.publicURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      {/* Start Omni Channel Section */}
      <section id="omni-channel" className="uk-section">
        <div className="uk-section uk-with-1-1">
          <div className="uk-container">
            <div uk-grid="">
              <div className="uk-width-1-2@m">
                <div className="uk-flex uk-flex-center">
                  <img src={omniChannelFigure.publicURL} alt="" />
                </div>
              </div>
              <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                <div>
                  <h2 className="uk-text-capitalize">{content.omni_channel.title}</h2>
                  <p className="uk-text-lead">{content.omni_channel.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-with-1-1">
          <div className="uk-container">
            <div className="uk-width-xlarge uk-margin-auto uk-text-center">
              <h3 className="uk-margin-large-bottom">{content.omni_channel.multiple_devices.title}</h3>
              <div className="uk-child-width-1-3@m" uk-height-match="target: > div > .uk-card" uk-grid="">
                <div>
                  <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <img src={oneConversationIcon.publicURL} alt="" />
                    <h4>{content.omni_channel.multiple_devices.one_conversation}</h4>
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <img src={intelligentIcon.publicURL} alt="" />
                    <h4>{content.omni_channel.multiple_devices.intelligent_message}</h4>
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <img src={easyIntegrationIcon.publicURL} alt="" />
                    <h4>{content.omni_channel.multiple_devices.easy_integration}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-with-1-1">
          <div className="uk-container">
            <div uk-grid="">
              <div className="uk-width-1-2@m">
                <h3>{content.omni_channel.benefits.title}</h3>
                <p>{content.omni_channel.benefits.description}</p>
                <div className="uk-child-width-1-3@m uk-margin-large-top" uk-grid="">
                  <div>
                    <img src={integratingVariousChannelIcon.publicURL} alt="" />
                    <h4>{content.omni_channel.benefits.integrating_channel}</h4>
                  </div>
                  <div>
                    <img src={comprehensiveDataIcon.publicURL} alt="" />
                    <h4>{content.omni_channel.benefits.more_comprehensive}</h4>
                  </div>
                  <div>
                    <img src={developStrategiesIcon.publicURL} alt="" />
                    <h4>{content.omni_channel.benefits.develop_strategies}</h4>
                  </div>
                  <div>
                    <img src={increaseProductivityIcon.publicURL} alt="" />
                    <h4>{content.omni_channel.benefits.productivity_and_sales}</h4>
                  </div>
                  <div>
                    <img src={driveTrafficIcon.publicURL} alt="" />
                    <h4>{content.omni_channel.benefits.traffic_and_sales}</h4>
                  </div>
                  <div>
                    <img src={betterCustomerExperienceIcon.publicURL} alt="" />
                    <h4>{content.omni_channel.benefits.better_customer_experience}</h4>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2@m uk-flex uk-flex-center uk-flex-middle">
                <div>
                  <img src={benefitFigure.publicURL} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <StudyCaseSlider data={omnichannelStudyCase} language={i18n.language} />
      </section>

      <BottomCta color="#462973" />
      <OtherProducts products={content.other_product} />
    </>
  )
}

export default SaktiPage
